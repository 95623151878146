import React from 'react'
import { Link } from 'gatsby'

import Icon from '../Icon'
import Button from '../Button'
import ContentWrapper from '../ContentWrapper'
import * as s from './style.module.sass'

const DidYouKnow = ({ text, cta, link }) => (
  <ContentWrapper>
    <div className={s.informationBlock}>
      <div className={s.informationContainer}>
        <Icon icon="bulb" iconSize="large" containerSize="large" />
        <p>{text}</p>
      </div>

      <Link to={link} className={s.link}>
        <Button 
          type="full"
        >
          <Icon
            icon="rightArrow"
            containerSize="xsmall"
            style={{ marginRight: 10 }}
          />
          {cta}
        </Button>
      </Link>
    </div>
  </ContentWrapper>
)

export default DidYouKnow
