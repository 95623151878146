// extracted by mini-css-extract-plugin
export var wrapper = "style-module--wrapper--0HUtd";
export var withInnerTitle = "style-module--withInnerTitle--lmov+";
export var blueBackground = "style-module--blueBackground--e1GyR";
export var textContainer = "style-module--textContainer--Gv7vh";
export var subTitle = "style-module--subTitle--nj0g2";
export var presentation = "style-module--presentation--JUYgt";
export var imgContainer = "style-module--imgContainer--+0-6n";
export var features = "style-module--features--5ODmd";
export var innerTitleContainer = "style-module--innerTitleContainer--6FHtc";
export var featureItem = "style-module--featureItem--tvZ-6";
export var ctaContainer = "style-module--ctaContainer--b430i";