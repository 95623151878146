import React from 'react'
import ReactMarkdown from 'react-markdown'
// import { openPopupWidget } from 'react-calendly'

import ContentWrapper from '../ContentWrapper'
import Button from '../Button'
import WithHtmlLinebreaks from '../WithHtmlLinebreaks'
import * as s from './style.module.sass'

// const CalendlyButton = ({ url }) => {
//   const onClick = () => openPopupWidget({ url })

//   // typeof window !== "undefined" && window.gtag("event", 'Website - ArtelinkHelpsYou - Meet a mission manager')

//   return <button onClick={onClick} onKeyDown={onClick} className={s.calendlyButton}>Prendre rendez-vous avec un Mission Manager</button>
// }

const ArtelinkHelpsYou = ({ title, subTitle }) => (
  <div className={s.wrapper}>
    <ContentWrapper className={s.contentWrapper}>
      <h1><ReactMarkdown>{title}</ReactMarkdown></h1>
      <p className={s.subTitle}>
        <WithHtmlLinebreaks text={subTitle} />
      </p>
      <div className={s.buttonsContainer}>
        <a href={`${process.env.GATSBY_APP_URL}/signup`} onClick={() => typeof window !== "undefined" && window.gtag("event", 'Website - ArtelinkHelpsYou - SignUp')}>
          <Button>Trouver un expert</Button>
        </a>

        {/* <Button type="outlined">
          <CalendlyButton url='https://calendly.com/stephanie-artelink/rdv-15-min-artelink' />
        </Button> */}
      </div>
    </ContentWrapper>
  </div>
)

export default ArtelinkHelpsYou
