import React from 'react'
import { graphql } from 'gatsby'

import { Helmet } from 'react-helmet'

import Layout from '../components/Layout'
import ArtelinkHelpsYou from '../components/ArtelinkHelpsYou'
import Operation from '../components/Operation'
import Benefits from '../components/Benefits'
import Presentation from '../components/Presentation'

const FonctionnementPageTemplate = ({
  topSection,
  operationSection,
  benefitsSection,
  legalAccompanimentSection
}) => {
  return (
    <>
      <ArtelinkHelpsYou {...topSection} />
      <Operation {...operationSection} />
      <Benefits
        benefitsTitle={benefitsSection.title}
        benefitsImage={benefitsSection.image}
        benefits={benefitsSection.benefits}
      />
      <Presentation innerTitle {...legalAccompanimentSection} /> 
    </>
  )
}

const FonctionnementPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark

  return (
    <Layout>
      <Helmet>
        <title>Trouvez en quelques clics l’expert ingénieur qu’il vous faut avec notre plateforme spécialisée</title>
        <meta name="description" content="Filtrez selon votre besoin les profils experts inscrits sur Artelink. Discutez, échangez et contractualisez avec eux directement sur Artelink. Profils 100% vérifiés." />
        {/* Open Graph */}
        <meta name="og:title" content="Artelink - Trouvez en quelques clics l’expert ingénieur qu’il vous faut avec notre plateforme spécialisée" />
        <meta name="og:image" content="" />
        <meta name="og:url" content="" />
        <meta name="og:description" content="Filtrez selon votre besoin les profils experts inscrits sur Artelink. Discutez, échangez et contractualisez avec eux directement sur Artelink. Profils 100% vérifiés." />
      </Helmet>

      <FonctionnementPageTemplate {...frontmatter} />
    </Layout>
  )
}

export default FonctionnementPage

export const pageQuery = graphql`
  query FonctionnementPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "fonctionnement-page" } }) {
      frontmatter {
        topSection {
          title
          subTitle
        }
        operationSection {
          title
          subTitle
          steps {
            title
            text
          }
          blueText
          cta
        }
        benefitsSection {
          title
          image {
            publicURL
          }
          benefits {
            text
            icon {
              publicURL
            }
          }
        }
        legalAccompanimentSection {
          image {
            childImageSharp {
              gatsbyImageData
            }
            extension
            publicURL
          }
          title
          subTitle
          features
          preCtaText
          cta
        }
      }
    }
  }
`