import React from 'react'
import ReactMarkdown from 'react-markdown'
import ContentWrapper from '../ContentWrapper'
import * as s from './style.module.sass'
import MissionSteps from '../MissionSteps';
import DidYouKnow from '../DidYouKnow'

const Operation = ({ title, subTitle, steps, blueText, cta }) => (
  <div className={s.wrapper}>
    <div className={s.blueWrapper}>
      <ContentWrapper className={s.contentWrapper}>
        <h1><ReactMarkdown>{title}</ReactMarkdown></h1>
        <p className={s.subTitle}>{subTitle}</p>
      </ContentWrapper>
      <MissionSteps steps={steps} withArrows={false} />
    </div>
    <DidYouKnow text={blueText} cta={cta} link='/a-propos' />
  </div>
)

export default Operation
