import React from 'react'
import { GatsbyImage } from "gatsby-plugin-image"

import Icon from '../Icon'
import Button from '../Button'
import ReactMarkdown from 'react-markdown'
import ContentWrapper from '../ContentWrapper'

import * as s from './style.module.sass'

const Presentation = ({ title, subTitle, image, features, preCtaText, cta, innerTitle = false }) => (
  <div className={[s.wrapper, innerTitle ? s.withInnerTitle : ''].join(' ')}>
    <div className={[s.blueBackground, innerTitle ? s.withInnerTitle : ''].join(' ')} />
    <ContentWrapper>
      {!innerTitle && (
        <div className={s.textContainer}>
          <h1><ReactMarkdown>{title}</ReactMarkdown></h1>
          <p className={s.subTitle}>{subTitle}</p>
        </div>
      )}
      <div className={s.presentation}>
        { image.extension === 'svg' && <img src={image.publicURL} className={s.imgContainer} alt="dashboard" /> }
        { image.extension !== 'svg' && <GatsbyImage image={image.childImageSharp.gatsbyImageData} className={s.imgContainer} alt="dashboard" /> }
        <div className={[s.features, innerTitle ? s.withInnerTitle : ''].join(' ')}>
          {innerTitle && (
            <div className={s.innerTitleContainer}>
              <h1>{title}</h1>
              <p>{subTitle}</p>
            </div>
          )}
          <ul>
            {features.map((feature, i) => (
              <li className={s.featureItem} key={i}>
                <Icon icon="rightArrow" iconSize="xsmall" containerSize="xsmall" />
                <span>{feature}</span>
              </li>
            ))}
          </ul>
        </div>
      </div>
      <div className={s.ctaContainer}>
        <h2>
          <ReactMarkdown>{preCtaText}</ReactMarkdown>
        </h2>
        {/* Calendly ou Zcal à mettre à la place */}
        <a href='mailto:contact@artelink.com'>
          <Button>{cta}</Button>
        </a>
      </div>
    </ContentWrapper>
  </div>
)

export default Presentation
