import React from 'react'
import ReactMarkdown from 'react-markdown'
import Icon from '../Icon'
import ContentWrapper from '../ContentWrapper'
import * as s from './style.module.sass'

const MissionSteps = ({ title, subTitle, steps, withArrows = true }) => (
  <ContentWrapper className={s.wrapper}>
    <div className={s.textContainer}>
      <h1><ReactMarkdown>{title}</ReactMarkdown></h1>
      {/* <p>{subTitle}</p> */}
    </div>
    <div className={s.stepsContainer}>
      {steps.map((step, i) => (
        <React.Fragment key={i}>
          <div
            className={[s.step, i % 2 === 1 ? s.marginTop : '', !withArrows ? s.biggerMargin : ''].join(' ')}
            key={i}
          >
            <div className={s.stepNb}>{i + 1}</div>
            <h3 className={s.title}>{step.title}</h3>
            <p>{step.text}</p>
          </div>
          {withArrows && i < steps.length - 1 && (
            <div className={s.iconContainer}>
              <Icon icon="rightArrow" />
            </div>
          )}
        </React.Fragment>
      ))}
    </div>
  </ContentWrapper>
)

export default MissionSteps
